.inicioDeSesin {
    display: flex;
    height: 100vh;
    background-color: #fff;
    font-family: Poppins, sans-serif;
    margin: 10px;
    border-radius: 30px;
  }
  
  .leftSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .imgIniciosesionIcon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .rightSection {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .logoWrapper {
    text-align: center;

  }
  
  .logoIcon {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .testflow {
    margin-top: 20px;
    font-size: 35px;
    font-weight: 500;
    color: #742b90;
  }
  
  .formWrapper {
    width: 80%;
    max-width: 400px;
    margin-top: 40px;
  }
  
  .inicioDeSesin1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  .textField {
    margin-bottom: 20px;
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #666;
  }
  
  .textFieldInput {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(102, 102, 102, 0.35);
  }
  
  .passwordWrapper {
    position: relative;
  }
  
  .passwordToggle {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    color: rgba(102, 102, 102, 0.8);
    font-size: 14px;
  }
  
  .icon {
    cursor: pointer;
    font-size: 1.2rem;
    margin-left: 10px;
  }
  
  
  .forgotPassword {
    text-align: right;
    color: #111;
    text-decoration: underline;
    margin-bottom: 20px;
  }
  
  .submitButton {
    width: 100%;
    padding: 15px;
    background-color: #742b90;
    justify-content: center; 
    
    color: #fff;
    border: none;
    border-radius: 32px;
    font-size: 18px;
    opacity: 0.25;
  }
  
  .submitButton.active {
    opacity: 1;
  }
  
  .googleButton {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    color: #5f6368;
    cursor: pointer;
    width: 100%;
    max-width: 250px;
    justify-content: center;
    transition: background-color 0.3s;
    margin: 40px 80px;
  }
  
  .googleButton:hover {
    background-color: #f7f7f7;
  }
  
  .googleIcon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
  .alertContainer {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    width: 400px;
  }

@media screen and (max-width: 768px) {
    .inicioDeSesin {
      flex-direction: column;
      margin: 20px;
      border-radius: 10px;
    }
  
    .leftSection {
      height: 50vh;
    }
  
    .rightSection {
      height: 50vh;
    }
  
    .formWrapper {
      width: 100%;
    }

    .googleButton {
      margin: 20px 30px;
    }
  
}