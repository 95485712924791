.contenedor {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.lista {
    margin-top: 15px;
}

.alumno {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: background-color 0.3s;
}

.alumno:hover {
    background-color: #f0f0f0; /* Cambia de color al pasar el mouse */
}

.foto {
    width: 50px;
    height: 50px;
    border-radius: 50%; /* Círculo */
    background-color: #ddd; /* Color de fondo como marcador de lugar */
    margin-right: 15px;
}

.info {
    flex-grow: 1;
}

h4 {
    margin: 0;
    font-size: 16px;
}

p {
    margin: 5px 0;
    font-size: 14px;
}
