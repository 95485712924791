/* Centrar el componente en el contenedor */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30;
}

/* Contenedor principal del elemento Examen */
.examenItemContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 85%;
    margin: 10px 0;
}

/* Información del examen */
.examenInfo {
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: #333;
}

.examenTitulo {
    font-weight: bold;
}

.examenFecha {
    color: #666;
}

/* Contenedor de los botones de iconos */
.iconButtons {
    display: flex;
    gap: 10px;
}

.iconButton {
    background-color: #6f2dbd;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    font-size: 18px;
}

.iconButton:hover {
    background-color: #4a148c;
}
