.resultado2Icon {
	width: 45px; 
	height: 45px;
	object-fit: cover;
  }
  
  .testflow {
	font-weight: 600;
	font-size: 25px;
	color: #742b90;
	font-family: 'Poppins', sans-serif;
  }
  
  .logo {
	display: flex;
	align-items: center;
	gap: 12px; 
  }
  
  .userIcon {
	font-size: 30px; 
	color: #742b90;
  }
  

  .lockIcon {
	color: #592B87;	
  }
  
  .userSection {
	display: flex;
	align-items: center;
	gap: 8px; 
  }
  
  .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 40px; 
	background-color: #fff;
	font-family: 'Poppins', sans-serif;
	border-bottom: 1px solid #e0e0e0; 
  }
  