.contenedor {
    max-width: 400px;
    margin: 20px auto;
    text-align: center;
    padding: 20px;
    background-color: white; /* Fondo blanco */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.input {
    margin-bottom: 15px;
    padding: 10px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.selectContainer {
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px; /* Bordes redondeados */
    overflow: hidden; /* Para asegurarnos de que los bordes no se salgan */
}

.select {
    padding: 10px;
    width: 100%;
    border: none; /* Eliminar el borde por defecto del select */
    font-size: 16px;
    background-color: #fff; /* Fondo blanco */
    cursor: pointer; /* Cambiar el cursor al pasar sobre el select */
}

.select:focus {
    outline: none; /* Sin contorno en el focus */
}

.mensaje {
    margin-top: 15px;
    font-weight: bold;
    color: #333;
}
