.formContainer {
  width: 85%;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  box-sizing: border-box;
}
.titulo {
  font-size: 24px;         /* Tamaño de fuente */
  font-weight: bold;       /* Negrita */
  color: #4a148c;          /* Color púrpura */
  text-align: center;      /* Centrado */
  margin-bottom: 20px;     /* Espacio debajo */
}
.examForm {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formGroup label {
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input[type="text"],
.formGroup input[type="number"],
.formGroup select {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.checkboxGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dificultadRange {
  width: 100%;
  margin-top: 5px;
  transition: background-color 0.3s ease;
}
.dificultadFacil {
  background-color: green;
}

.dificultadMedio {
  background-color: orange;
}

.dificultadDificil {
  background-color: red;
}
.largeCheckbox {
  width: 20px;
  height: 20px;
}
.dificultadLabels {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 5px;
}
.buttonGroup {
  display: flex;
  justify-content: center; /* Centra los botones */
  gap: 10px; /* Espacio entre botones */
}

button {
  padding: 15px 80px; /* Reduce el tamaño del botón */
  background-color: #800080;
  color: white;
  border: none;
  border-radius: 25px; /* Botón más redondeado */
  font-size: 14px; /* Tamaño de texto más pequeño */
  cursor: pointer;
  width: auto; /* Ajusta el ancho según el contenido */
  transition: background-color 0.3s ease; /* Animación al pasar el mouse */
}

button:hover {
  background-color: #5a005a; /* Color más oscuro al pasar el mouse */
}


/* Media Query para Responsividad */
@media (min-width: 100px) {
  .examForm {
      grid-template-columns: 1fr 1fr;
  }
  
  .buttonGroup {
      grid-column: span 2;
  }
}
/* Estilo fijo para el textarea de instrucciones */
.fixedTextarea {
  width: 100%;
  height: 100px; /* Fija la altura */
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none; /* Evita que se pueda redimensionar */
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
}

/* Estilo personalizado para el input de fecha */
.styledDate {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
}

.styledDate::-webkit-calendar-picker-indicator {
  filter: hue-rotate(300deg); /* Cambia el color del ícono del calendario */
}

/* Asegurar que input type="number" mantenga estilos consistentes */
input[type="number"] {
  -moz-appearance: textfield; /* Elimina las flechas en Firefox */
  appearance: textfield; /* Estándar para compatibilidad */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Elimina las flechas en Chrome/Safari */
  margin: 0;
}
.submitButton {
  width: 100%;
  padding: 15px;
  background-color: #742b90;
  justify-content: center; 
  
  color: #fff;
  border: none;
  border-radius: 32px;
  font-size: 18px;
  opacity: 0.25;
}
