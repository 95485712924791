.table-container {
  background-color: white; /* Fondo blanco */
  padding: 20px; /* Espacio alrededor de la tabla */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
  width: 80%; /* Asegura que se ajuste al ancho de la tabla */
  margin: 0 auto; /* Centrar el contenedor */
}

.user-table {
  width: 100%; /* Ajusta la tabla al ancho del contenedor */
  border-collapse: collapse;
}
  .user-table th,
  .user-table td {
    padding: 12px 15px;
    text-align: center; /* Centrar el contenido de las celdas */
    border-bottom: 1px solid #cccbcb;
  }
  
  .user-table th {
    background-color: #8D288E;
    color: white;
    font-weight: bold;
  }
  
  .user-table tr:nth-child(even) {
    background-color: #ffff;
  }
  
  .user-table .actions {
    text-align: center;
  }
  
  .user-table .edit-btn,
  .user-table .delete-btn {
    background-color: #592B87;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
    transition: background-color 0.3s;
  }
  
  .user-table .edit-btn {
    background-color: #8D288E;
  }
  
  .user-table .delete-btn {
    background-color: #C9C9C9;
  }
  
  .user-table .edit-btn:hover {
    background-color: #592B87;
  }
  
  .user-table .delete-btn:hover {
    background-color: #8D288E;
  }
  
  @media (max-width: 768px) {
    .user-table th,
    .user-table td {
      padding: 8px 10px;
      font-size: 14px;
    }
  
    .user-table th:nth-child(4),
    .user-table th:nth-child(5),
    .user-table td:nth-child(4),
    .user-table td:nth-child(5) {
      display: none;
    }
  }
  .search-select-container {
    display: flex;
    justify-content: space-between; /* Espacio entre los elementos */
    align-items: center;
    width: 95%; /* Ocupa el 80% del ancho total para dejar 10% de margen en cada lado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    margin-bottom: 10px; /* Espacio opcional debajo del contenedor */
  }
  
  .add-button {
    margin-left: 35px; /* Opcional, ajusta el espacio en el lado derecho si lo necesitas */
  }
  /* Contenedor de los botones de acción */
.acciones-button {
  display: flex;
  gap: 10px; /* Espacio entre los botones */
  justify-content: center; /* Centra los botones dentro del contenedor */
}

.fixed-instructions {
  width: 300px; /* Ajusta según sea necesario */
  height: 100px; /* Ajusta según sea necesario */
  resize: none; /* Evita que se redimensione */
}