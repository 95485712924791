.tabAdministrador {
    display: flex;
    justify-content: center; /* Alinea las opciones al centro */
    gap: 20px; /* Espacio reducido entre las dos opciones */
    padding: 20px 40px; /* Igual al padding del header */
    background-color: #f5f5f5;
    border-radius: 12px;
    width: 100%; /* Ancho al 100% para ajustarse al contenedor */
    box-sizing: border-box; /* Asegura que el padding no afecte el ancho */
}

  
  .tabOption {
    display: flex;
    align-items: center;
    padding: 15px; /* Espacio interior ajustado */
    border: 2px solid transparent;
    border-radius: 12px;
    background-color: #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
    width: 230px; /* Ancho ajustado */
    justify-content: center; /* Centra el contenido en cada tarjeta */
    gap: 10px; /* Espacio reducido entre ícono y texto */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .tabOption:hover {
    border-color: #742b90; /* Color del borde al pasar el ratón */
  }
  
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .iconBackground {
    background-color: rgba(118, 80, 172, 0.1); /* Fondo gris claro */
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    font-size: 24px;
    color: #742b90; /* Color del icono */
  }
  
  .label {
    font-family: 'DM Sans', sans-serif;
    font-size: 16px; /* Tamaño ajustado */
    color: #6f6c90;
  }
  
  .selected {
    border-color: #742b90; /* Color del borde cuando está seleccionado */
  }
  