.filterContainer {
    display: flex;
    justify-content: flex-start; 
    margin: 20px 0;
   /* Alejar el filtro un 20% de la pantalla */
  }
  
  .select {
    width: 250px;
    padding: 10px 15px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    border-radius: 30px;
    border: 1px solid #C9C9C9;
    background-color: #fff;
    color: #6f6c90; /* Color de texto */
    transition: all 0.3s ease;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .select:hover,
  .select:focus {
    border-color: #8D288E;
    outline: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    color: #8D288E; /* Color de texto al pasar el cursor o al hacer foco */
  }
  
  .select option {
    padding: 10px;
    background-color: white;
    color: #6f6c90; /* Color de texto en las opciones */
  }
  